<template>
  <card class="instruction">
    <h3 class="instruction__title">{{ title }}</h3>
    <vimeo
      v-if="isVideo"
      :url="video.url"
      :id="video.id"
      class="instruction__video"
      :disabled="recording"
      key="video"
    />

    <ol class="list" v-else>
      <li>
        Sit upright with your mouth ~14 inches (36 cm) from the microphone
      </li>
      <li>
        Press <span class="instruction__success"><b>"Record"</b></span>
      </li>
      <li>
        Inhale deeply through your nose and cough without covering your mouth. 
        Repeat (inhale and cough) two more times for a total of 3 coughs
      </li>
      <li>
        Press <span class="instruction__danger"><b>"Stop"</b></span> after the third cough
      </li>
    </ol>

    <div class="instruction__links">
      <a
        v-if="isVideo"
        @click.prevent="changeView('text')"
        href="#"
        class="instruction__link"
      >
        {{ titles["text"].link }}
      </a>
      <a
        v-else
        @click.prevent="changeView('video')"
        href="#"
        class="instruction__link"
      >
        {{ titles["video"].link }}
      </a>
    </div>

  </card>
</template>

<script>
import Instructions from "@/mixins/instructions";
import Card from "@/components/card";
import { isIOS } from "@/utils/system";

export default {
  components: { Card },

  mixins: [Instructions],

  mounted() {
        if (isIOS){
          this.changeView("text");
        }
  },

  data: () => ({
    video: {
      url: "https://vimeo.com/473663448/941a5a4d0c",
      id: "473663448",
    }
  }),
};
</script>
